const aquamarket = {
    loginTitle: {
        phoneNumber: 'Укажите телефон',
        email: 'Укажите электронную почту',
        smsCode: 'Введите {{codeLength}}-значный код',
        info: 'Давайте знакомиться',
    },
    loginSubtitle: {
        phoneNumber:
            'Чтобы зарегистрироваться в системе и совершать покупки\n\nПосле нажатия на кнопку вам поступит звонок-сброс. Отвечать на звонок не нужно.',
        info: 'Как вас зовут?',
        smsCode: 'Введите последние {{codeLength}} цифры входящего звонка',
    },
    bonusesField: {
        title: 'Накоплено баллов',
        use: 'Списать баллы',
        info: '{{maxCashbackPoints}} баллов',
    },
    order: {
        guide: {
            title: 'Как купить',
            step1: 'После оплаты откроется ячейка. Это может занять несколько секунд',
            step2: 'Заберите товар',
            step3: 'Положите в ячейку пустую бутыль',
            step4: 'Не забудьте закрыть ячейку',
        },
        order: {
            total: 'К оплате: ',
            mainBtn: 'Оплатить {{total}} {{currency}}',
            toPay: 'Оплатить',
            bonuses: 'Баллы за покупку',
            error: 'Не удалось завершить оплату. Пожалуйста, попробуйте позже.',
        },
        orderItem: {
            rent: 'аренда',
            quantity: '{{quantity}} шт',
            yourPurchase: 'Ваша покупка',
        },
        returnedBottlesNotification: {
            returnableContainerName: 'бутыль (19 л)',
            title: 'Условия предоставления бутыли в пользование',
            paragraph1: 'Если у вас нет обменной бутыли, вы можете воспользоваться услугой аренды.',
            paragraph2: 'Как это работает?',
            paragraph3:
                'Вы оплачиваете только воду, без учета стоимости тары, а при следующих покупках оставляете эту пустую бутыль в ячейке.',
            paragraph4: 'Соглашаясь с условиями услуги, вы обязуетесь:',
            paragraph5: 'Приобретать воду в микромаркете не реже, чем 1 раз в {{rentPeriod}} дней',
            paragraph6:
                'Бережно обращаться с контейнером. Контейнер с трещинами и с резким запахом не принимается',
            paragraph7: 'Возвращать пустую бутыль в ячейку при каждой покупке.',
            paragraph8: 'В случае отказа от услуги, вам потребуется вернуть бутыль компании:',
            paragraph9: 'Лично приехать на склад и передать тару специалистам',
            paragraph10:
                'Передать бутыль курьеру при пополнении микромаркета. Для этого оформите заказ возврата тары по телефону {{phone}}',
            paragraph11:
                'Также вы можете выкупить ее, оплатив полную стоимость в размере {{price}} руб. О способе оплаты вам сообщит оператор по телефону.',
            acceptBtn: 'Все понятно',
        },
        returnedBottles: {
            title: 'У вас есть пустая бутыль 19 л?',
            paragraph1:
                'В этом Микромаркете вы можете обменять пустую многоразовую {{returnableContainerName}} на полную, оплатив только воду.',
            paragraph2: 'Если пустой бутыли у вас еще нет, вы можете ',
            rentBtn: 'воспользоваться услугой аренды',
            buyBtn: 'выкупить ее',
            paragraph3: 'и в будущем обменять на полную.',
            radio: {
                have: 'У меня есть тара для обмена',
                rent: 'Хочу взять в аренду',
                buy: 'У меня нет тары для обмена',
                ok: 'Хорошо',
                conditions: {
                    byPlacingOrder: 'При оформлении заказа вы соглашаетесь с ',
                    freeUse: 'условиями бесплатного использования',
                    exchangeContainer: 'обменной тары',
                },
            },
            leaveBottle: 'Не забудьте оставить пустую бутыль в ячейке',
        },
        safePayment: {
            text:
                'Мы используем защищенную форму оплаты, ваши данные карты не сохраняются и не передаются третьим лицам',
        },
        paymentItem: {
            deleteModal: {
                delete: 'Удалить',
                card: 'карту',
                paymentMethod: 'способ оплаты',
                fromProfile: 'из профиля?',
            },
            deleteCardSuccess: 'Карта успешно удалена',
            deletePaymentMethodSuccess: 'Способ оплаты успешно удален',
            delete: 'Удалить',
            cancel: 'Отмена',
            sbp: 'Счет СБП',
        },
        status: {
            waiting: {
                title: 'Немного терпения',
                subtitle: 'На обработку платежа требуется до 1 минуты',
            },
            success: {
                title: 'Спасибо!',
                subtitle: 'Ваша покупка успешно оплачена',
                additional: 'Дверца ячейки откроется в течение одной минуты',
            },
            error: {
                title: 'Ваш платеж не прошел',
                subtitle: 'Возможно это временный сбой. Попробуйте снова.',
            },
            support: {
                text: 'Если ячейка не открылась, напишите в техподдержку или позвоните ',
            },
        },
        unableCell: {
            title: {
                disabled: 'Эта ячейка не доступна для покупки в данный момент',
                inactive: 'Ведутся технические работы',
                error: 'Произошла ошибка при загрузке ячейки. Повторите попытку позже',
                empty: 'Эта ячейка пустая',
            },
            subtitle: {
                inactive: 'Микромаркет в данный момент недоступен, но мы скоро все починим.',
                default: 'Отсканируйте QR-код другой ячейки с горящим зеленым индикатором.',
            },
        },
    },
};

export default aquamarket;
